import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Post } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, useEntity } from '@shapeable/ui';
import { PostBannerLayout } from '@shapeable/web';
import { classNames } from '@shapeable/utils';
const cls = classNames('post-layout');

// -------- Types -------->

export type PostLayoutProps = Classable & HasChildren & { 
  entity?: Post;
};

export const PostLayoutDefaultProps: Omit<PostLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PostBannerLayout)<ContainerProps>`${ContainerStyles}`,
};

export const PostLayout: Shapeable.FC<PostLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <ComponentPropsProvider value={{ 
      PageMeta: {
        alignAuthors: true,
        photoSize: 60,
        useTooltip: true,
      }
    }}>
   <My.Container className={cls.name(className)} entity={entity} />
   </ComponentPropsProvider>
  )
};

PostLayout.defaultProps = PostLayoutDefaultProps;
PostLayout.cls = cls;